$('.js-gallery').slick({
    prevArrow: '<div class="slider-button slider-arrow slick-prev" \
    aria-label="Prev"><svg width="16" height="23" viewBox="0 0 16 23"\
     class="svg-icon"><use width="16" height="23" viewBox="0 0 16 23"\
      xlink:href="#svg-left"></use></svg></div>',
    nextArrow: '<div class="slider-button slider-arrow slick-next"\
     aria-label="Next"><svg  width="16" height="23" viewBox="0 0 16 23" \
     class="svg-icon"><use  width="16" height="23" viewBox="0 0 16 23" \
     xlink:href="#svg-right"></use></svg></div>',

});

$('.js-gallery-2').slick({
  prevArrow: '<div class="slider-button slider-arrow slick-prev" \
  aria-label="Prev"><svg width="16" height="23" viewBox="0 0 16 23"\
   class="svg-icon"><use width="16" height="23" viewBox="0 0 16 23"\
    xlink:href="#svg-left"></use></svg></div>',
  nextArrow: '<div class="slider-button slider-arrow slick-next"\
   aria-label="Next"><svg  width="16" height="23" viewBox="0 0 16 23" \
   class="svg-icon"><use  width="16" height="23" viewBox="0 0 16 23" \
   xlink:href="#svg-right"></use></svg></div>',

});

$('.js-main-banner').slick({
  prevArrow: '<div class="slider-button slider-arrow slick-prev" \
  aria-label="Prev"><svg width="16" height="23" viewBox="0 0 16 23"\
   class="svg-icon"><use width="16" height="23" viewBox="0 0 16 23"\
    xlink:href="#svg-left"></use></svg></div>',
  nextArrow: '<div class="slider-button slider-arrow slick-next"\
   aria-label="Next"><svg  width="16" height="23" viewBox="0 0 16 23" \
   class="svg-icon"><use  width="16" height="23" viewBox="0 0 16 23" \
   xlink:href="#svg-right"></use></svg></div>',
   arrows: true,
   dots: true,
});

$('.slider-for-1').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav-1'
  });

  $('.slider-nav-1').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for-1',
    vertical: true,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          vertical: false,
        }
      }
    ]
  });

  $('.slider-for-2').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav-2'
  });

  $('.slider-nav-2').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for-2',
    vertical: true,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          vertical: false,
        }
      }
    ]
  });


  $(".js-phone").mask("+7(999)999-99-99");

  $(".js-range-year").slider({
    range: "max",
    min: 1,
    max: 10,
    value: 2,
    slide: function( event, ui ) {
      let year;
      if (ui.value == 1) {
        year = " день"
      } else if ((ui.value > 1) && (ui.value < 5)) {
        year = " дня"
      } else {
        year = " дней"
      }

      $(".js-range-year-input").val( ui.value + year);
    }
  });

  let year;
    if ($( ".js-range-year" ).slider( "value" ) == 1) {
      year = " день"
    } else if (($( ".js-range-year" ).slider( "value" ) > 1) && ($( ".js-range-year" ).slider( "value" ) < 5)) {
      year = " дня"
    } else {
      year = " дней"
    }

  $( ".js-range-year-input" ).val( $( ".js-range-year" ).slider( "value" ) + year);

 

  $(".js-range-count").slider({
    range: "max",
    min: 1,
    max: 60000,
    value: 15000,
    slide: function( event, ui ) {
      $(".js-range-count-input").val( String(ui.value).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') );
    }
  });

  $( ".js-range-count-input" ).val( String($( ".js-range-count" ).slider( "value" )).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '));


  
$(window).scroll(function() { 
  var the_top = $(document).scrollTop();
  if (the_top > 150) {
      $('.js-header').addClass('is-fixed');

  } 
  else {
      $('.js-header').removeClass('is-fixed');
  }
});

if ($(window).width() < 1200) {
  $(".multiitem").on( "click", function() {
   

    if(!$(this).hasClass("is-active")) {
      $(".l-dropdown" ).slideUp(300, function() {});
      
      $(".multiitem").removeClass("is-active");
      $(this).addClass("is-active");
      $(this).next(".l-dropdown" ).slideDown(300, function() {});
    } else {
      $(this).removeClass("is-active");
      $(this).next(".l-dropdown" ).slideUp(300, function() {});
    }


    return false
  });


  $(".js-hamburger").on( "click", function() {
    $(".js-nav").addClass("is-open");
  });

  $(".js-close-nav").on( "click", function() {
    $(".js-nav").removeClass("is-open");
  });
  
}

$('.js-select').styler();